import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {PriceSlider} from '../PriceSlider/PriceSlider';
import {IFilterOption, IRangeValue} from '../../types/types';
import * as _ from 'lodash';

export interface IRangeFilterProps extends IProvidedGlobalProps {
  filterId: number;
  options: IFilterOption[];
  selectedRange: {
    min: string;
    max: string;
  };
}

interface IRangeFilterState {
  minIndex: number;
  maxIndex: number;
}

@withGlobalProps
export class RangeFilter extends React.Component<IRangeFilterProps, IRangeFilterState> {
  public shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.selectedRange, nextProps.selectedRange);
  }

  public render() {
    return (
      <PriceSlider
        formatLabel={this.formatLabel}
        minValue={0}
        maxValue={this.props.options.length - 1}
        onChange={(range: IRangeValue) => this.onChange(range, false)}
        onChangeComplete={(range: IRangeValue) => this.onChange(range, true)}
        value={this.value()}
      />
    );
  }

  private readonly value = () => {
    const min =
      this.props.selectedRange.min === ''
        ? 0
        : this.props.options.findIndex(o => o.key === this.props.selectedRange.min);
    const max =
      this.props.selectedRange.max === ''
        ? this.props.options.length - 1
        : this.props.options.findIndex(o => o.key === this.props.selectedRange.max);
    return {min, max};
  };

  public formatLabel = (_index, type) => {
    const range = this.value();
    return range.hasOwnProperty(type) ? this.props.options[range[type]].value : '';
  };

  public onChange = (range: IRangeValue, isChangeComplete: boolean) => {
    const {
      filterId,
      options,
      globals: {filterProducts},
    } = this.props;

    const values = {
      min: options[range.min].key,
      max: options[range.max].key,
    };

    filterProducts(filterId, values, isChangeComplete);
  };
}
