import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {CheckboxList} from '../CheckboxList/CheckboxList';
import * as _ from 'lodash';

export interface IMultipleSelectionFilterProps extends IProvidedGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selected: string[];
}

@withGlobalProps
export class MultipleSelectionFilter extends React.Component<IMultipleSelectionFilterProps> {
  private handleChange(e) {
    const selectedOptionId = e.target.value;
    this.props.globals.filterProducts(this.props.filterId, selectedOptionId);
  }

  public shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.selected, nextProps.selected);
  }

  public render() {
    const {options, selected} = this.props;

    return (
      <CheckboxList
        id={`${this.props.filterId}`}
        selected={selected}
        options={options}
        onChange={e => this.handleChange(e)}
      />
    );
  }
}
