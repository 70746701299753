import React from 'react';
import s from './MobileFiltersModal.scss';
import classNames from 'classnames';
import {Filters} from '../Filters/Filters';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';

export interface IMobileFiltersModal extends IProvidedGlobalProps {
  shouldShowClearFilters: boolean;
  clearFilters: Function;
  closeModal: Function;
  className: string;
}

@withGlobalProps
export class MobileFiltersModal extends React.Component<IMobileFiltersModal> {
  public render() {
    return (
      <>
        <div className={classNames(s.mobileFiltersModal, this.props.className)} data-hook="mobile-filters-modal">
          <Filters
            closeMobileFiltersModal={() => this.props.closeModal()}
            shouldShowClearFiltersButton={this.props.shouldShowClearFilters}
            clearFilters={this.props.clearFilters}
          />
        </div>
      </>
    );
  }
}
