import React from 'react';
import {IGalleryStyleParams, IPropsInjectedByViewerScript, IGallerySantaProps} from '../../types/types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

type ProvidedGlobalPropNames =
  | 'appLoadBI'
  | 'dimensions'
  | 'experiments'
  | 'filterModels'
  | 'filterProducts'
  | 'filterProductsOnMobile'
  | 'focusedProductIndex'
  | 'getCategoryProducts'
  | 'handleProductItemClick'
  | 'hasMoreProductsToLoad'
  | 'hasSelectedFilters'
  | 'isFirstPage'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isMobile'
  | 'isRTL'
  | 'loadMoreProducts'
  | 'loading'
  | 'mainCollectionId'
  | 'openQuickView'
  | 'productIdToProductPageUrlMap'
  | 'products'
  | 'setProductsPerPage'
  | 'sortProducts'
  | 'textsMap'
  | 'toggleFiltersModalVisibility'
  | 'totalProducts'
  | 'updateLayout';

export interface IProvidedGlobalProps {
  globals?: Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
    styleParams: IGalleryStyleParams;
  };
}

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
    const {
      experiments,
      filterModels,
      filterProducts,
      filterProductsOnMobile,
      focusedProductIndex,
      getCategoryProducts,
      handleProductItemClick,
      hasMoreProductsToLoad,
      hasSelectedFilters,
      isFirstPage,
      isInteractive,
      isLiveSiteMode,
      isMobile,
      isRTL,
      loadMoreProducts,
      loading,
      mainCollectionId,
      openQuickView,
      productIdToProductPageUrlMap,
      products,
      setProductsPerPage,
      sortProducts,
      style,
      textsMap,
      toggleFiltersModalVisibility,
      totalProducts,
      ...passThroughProps
    } = props;

    const {appLoadBI, dimensions, updateLayout} = props.host;

    return (
      <PropsContext.Provider
        value={{
          globals: {
            appLoadBI,
            dimensions,
            experiments,
            filterModels,
            filterProducts,
            filterProductsOnMobile,
            focusedProductIndex,
            getCategoryProducts,
            handleProductItemClick,
            hasMoreProductsToLoad,
            hasSelectedFilters,
            isFirstPage,
            isInteractive,
            isLiveSiteMode,
            isMobile,
            isRTL,
            loadMoreProducts,
            loading,
            mainCollectionId,
            openQuickView,
            productIdToProductPageUrlMap,
            products,
            setProductsPerPage,
            sortProducts,
            styleParams: style.styleParams,
            textsMap,
            toggleFiltersModalVisibility,
            totalProducts,
            updateLayout,
          },
        }}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => (
    <PropsContext.Consumer>
      {globalProps => <Component {...globalProps} {...props} ref={props.innerRef} />}
    </PropsContext.Consumer>
  );
}
