import React from 'react';
import s from './LoadMoreButton.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import classNames from 'classnames';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

interface LoadMoreButtonProps {
  loadMoreClicked: any;
}

@withGlobalProps
@withTranslations()
export class LoadMoreButton extends React.Component<
  LoadMoreButtonProps & IProvidedGlobalProps & IProvidedTranslationProps
> {
  public render() {
    return (
      <button
        data-hook="load-more-button"
        className={classNames(s.loadMore, {[s.isMobile]: this.props.globals.isMobile})}
        onClick={this.props.loadMoreClicked}>
        {this.props.t('loadMoreButtonText')}
      </button>
    );
  }
}
