const {
  withSentryErrorBoundary,
} = require('@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary');
const {GALLERY_PAGE_DSN} = require('@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf');
const {getWrappedComponent} = require('./galleryAppConfig');
const {GalleryApp} = require('./components/GalleryApp/GalleryApp');

const App = getWrappedComponent({entryName: 'gallery', Component: GalleryApp});

module.exports = {
  default: {
    component: withSentryErrorBoundary(App, {
      dsn: GALLERY_PAGE_DSN,
      config: {environment: 'Native Component'},
    }),
  },
};
