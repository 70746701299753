import React from 'react';
import {ProductList} from '../ProductList/ProductList';
import {Filters} from '../Filters/Filters';
import {IPropsInjectedByViewerScript, IGallerySantaProps} from '../../types/types';
import {Sort} from '../Sort/Sort';
import s from './GalleryApp.scss';
import classNames from 'classnames';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {MobileFiltersModal} from '../MobileFiltersModal/MobileFiltersModal';
import {CSSTransition} from 'react-transition-group';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

const inlineCss = require('!raw-loader!../../styles/inlineStyle.css');

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
}

export type IGalleryAppProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  IProvidedGlobalProps['globals']
> &
  IProvidedGlobalProps &
  IProvidedTranslationProps;

@withGlobalProps
@withTranslations()
@autobind
export class GalleryApp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;
  private windowScrollY: number;
  private originalBodyTop: string;

  constructor(props) {
    super(props);

    this.state = {
      isSSR: props.isSSR,
    };
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IProvidedGlobalProps) {
    this.announceIfFilterResultChanged(prevProps);
  }

  private openMobileFiltersModal() {
    this.saveBodyTopPosition();

    this.props.globals.toggleFiltersModalVisibility(true);

    document.body.classList.add('filters-modal-open');
    document.body.style.top = `-${this.windowScrollY}px`;
  }

  private closeMobileFiltersModal() {
    document.body.classList.remove('filters-modal-open');
    this.props.globals.toggleFiltersModalVisibility(false);

    this.restoreBodyTopPosition();
  }

  private saveBodyTopPosition() {
    this.originalBodyTop = document.body.style.top;
    this.windowScrollY = window.scrollY;
  }

  private restoreBodyTopPosition() {
    document.body.style.top = this.originalBodyTop;
    window.scrollTo(window.scrollX, this.windowScrollY);
  }

  private announceIfFilterResultChanged(prevProps) {
    const haveFiltersChanged = !_.isEqual(this.props.globals.filterModels, prevProps.globals.filterModels);

    if (haveFiltersChanged && this.props.globals.products) {
      this.a11yAnnouncer.announce(
        this.props.t('sr.ANNOUNCE_FOUND_ITEMS_ON_FILTERS_UPDATE', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      );
    }
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  private shouldShowGalleryApp() {
    return this.props.isLoaded;
  }

  private shouldShowFilters() {
    return this.props.globals.filterModels.length !== 0 && !this.props.globals.isMobile;
  }

  private shouldShowMobileFiltersButton() {
    return (
      this.props.globals.filterModels.length !== 0 &&
      this.props.globals.isMobile &&
      this.props.globals.experiments.isMobileFiltersEnabled
    );
  }

  private shouldShowSort() {
    return this.props.shouldShowSort && !this.props.globals.isMobile;
  }

  private shouldShowMobileSort() {
    return (
      this.props.shouldShowSort && this.props.globals.isMobile && this.props.globals.experiments.isMobileSortEnabled
    );
  }

  private renderFilters() {
    return (
      <Filters
        shouldShowClearFiltersButton={this.props.shouldShowClearFilters}
        clearFilters={this.props.clearFilters}
      />
    );
  }

  private getSelectedFiltersCount() {
    return `(${this.props.numberOfSelectedFilterTypes})`;
  }

  public render() {
    const {
      globals: {
        hasSelectedFilters,
        styleParams: {
          booleans: {full_width: isFullWidth},
        },
        isMobile,
      },
      shouldShowMobileFiltersModal,
    } = this.props;

    return (
      !!this.shouldShowGalleryApp() && (
        <>
          <style dangerouslySetInnerHTML={{__html: inlineCss}} />
          <div
            data-hook={DataHook.Root}
            className={classNames(s.galleryApp, {
              deviceMobile: isMobile,
            })}>
            <div data-hook={DataHook.Content} className={classNames(s.content, {[s.fullWidth]: isFullWidth})}>
              {this.shouldShowFilters() && <aside className={s.filters}>{this.renderFilters()}</aside>}
              <div
                data-hook={DataHook.Container}
                className={classNames(s.container, {[s.filtersAndProductList]: this.shouldShowFilters()})}>
                {this.shouldShowSort() && (
                  <div className={s.sortRow}>
                    <Sort />
                  </div>
                )}
                {this.shouldShowMobileFiltersButton() && (
                  <button
                    onClick={() => this.openMobileFiltersModal()}
                    className={s.mobileFiltersButton}
                    data-hook="mobile-filters-button">
                    {this.props.t('mobileFiltersButtonText')}
                    {this.props.numberOfSelectedFilterTypes !== 0 && (
                      <span className={s.filtersCount}> {this.getSelectedFiltersCount()}</span>
                    )}
                  </button>
                )}
                {this.shouldShowMobileSort() && (
                  <select data-hook="mobile-sort">
                    <option value={'1'}>Apple</option>
                  </select>
                )}
                <div className={this.shouldShowFilters() && !this.shouldShowSort() ? s.noSortPlaceholder : null} />
                <ProductList hasFilters={hasSelectedFilters} />
                <CSSTransition
                  in={shouldShowMobileFiltersModal}
                  timeout={{enter: 500, exit: 500}}
                  classNames="modal"
                  unmountOnExit>
                  <div>
                    <div className="overlay" />
                    <MobileFiltersModal
                      className="filters-modal"
                      closeModal={this.closeMobileFiltersModal}
                      clearFilters={this.props.clearFilters}
                      shouldShowClearFilters={this.props.shouldShowClearFilters}
                    />
                  </div>
                </CSSTransition>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}
