import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import s from './FiltersHeader.scss';
import {X} from '../../icons/dist';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface IFiltersHeaderProps extends IProvidedGlobalProps, IProvidedTranslationProps {
  closeMobileFiltersModal: Function;
}

@withGlobalProps
@withTranslations()
export class FiltersHeader extends React.Component<IFiltersHeaderProps> {
  public render() {
    const {isMobile} = this.props.globals;

    return (
      <>
        <span className={s.header}>
          <h2 data-hook="filters-title" className={s.title}>
            {this.props.t('filtersTitleText')}
          </h2>
          {isMobile && (
            <span data-hook="cancel" className={s.cancel} onClick={() => this.props.closeMobileFiltersModal()}>
              <X />
            </span>
          )}
        </span>
      </>
    );
  }
}
